import { InitConfig, Tags, Session, Extra } from '../typings';

import * as Sentry from '@sentry/browser';
import ignoreErrorsList from '../config/ignoreErrors';

/*
 * Level can be: 'fatal', 'error', 'warning', 'log', 'info', and 'debug'.
 */
const FATAL: Sentry.SeverityLevel = 'fatal';
const ERROR: Sentry.SeverityLevel = 'error';
const WARNING: Sentry.SeverityLevel = 'warning';
const LOG: Sentry.SeverityLevel = 'log';
const INFO: Sentry.SeverityLevel = 'info';
const DEBUG: Sentry.SeverityLevel = 'debug';

export default class Logger {
    private tags: Tags;

    constructor(tags: Tags = {}) {
        this.tags = tags;
    }

    public static init(conf: InitConfig, session: Session, globalTags: Tags = {}): void {
        const { user, ...sessionId }: Session = session;

        Sentry.init({
            beforeSend(event: Sentry.ErrorEvent) {
                return Logger.removeURLParameters(event);
            },
            environment: conf.environment,
            dsn: conf.dsn,
            autoSessionTracking: true,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.httpClientIntegration({
                    failedRequestStatusCodes: [
                        [400, 499],
                        [500, 599],
                    ],
                }),
                Sentry.captureConsoleIntegration(),
            ],
            tracesSampleRate: Number(conf.tracesSampleRate) || 0.6,
            ignoreErrors: ignoreErrorsList,
            sendDefaultPii: true,
        });

        globalTags = { ...sessionId, ...globalTags };

        const scope = Sentry.getCurrentScope();

        if (user) {
            scope.setUser(user);
        }

        scope.setTags(globalTags);
    }

    public debug(message: string, extra: Extra = {}): void {
        this.log(message, extra, DEBUG);
    }

    public info(message: string, extra: Extra = {}): void {
        this.log(message, extra, INFO);
    }

    public warn(message: string, extra: Extra = {}): void {
        this.log(message, extra, WARNING);
    }

    public warning(message: string, extra: Extra = {}): void {
        this.log(message, extra, WARNING);
    }

    public error(message: string, extra: Extra = {}): void {
        this.log(message, extra, ERROR);
    }

    public exception(e: ErrorEvent, extra: Extra = {}): void {
        Sentry.withScope((scope: Sentry.Scope) => {
            scope.setExtras(extra);
            scope.setTags(this.tags);
            scope.setLevel(ERROR);
            Sentry.captureException(e);
        });
    }

    private log(message: string, extra: Extra = {}, level: Sentry.SeverityLevel = DEBUG): void {
        Sentry.withScope((scope: Sentry.Scope) => {
            scope.setExtras(extra);
            scope.setTags(this.tags);
            scope.setLevel(level);
            Sentry.captureMessage(message);
        });
    }

    private static removeURLParameters(e: Sentry.ErrorEvent): Sentry.ErrorEvent {
        if (e.request && e.request.url) {
            e.request.url = Logger.deleteFromExcludedList(e.request.url);
        }

        return e;
    }

    public static deleteFromExcludedList(requestUrl: string): string {
        const EXCLUDE_LIST: string[] = ['sidk', 'videoSessionGUID', 'videoSessionID'];

        const url: URL = new URL(requestUrl);
        const pathnameParams: string[] = url.pathname.split('&');
        const pathname: string = pathnameParams[0];

        let query: string = url.search.slice(1);
        query = query.replace('?', '&');

        if (pathnameParams.length > 1 && query) {
            query = [pathnameParams.slice(1).join('&'), url.search.slice(1)].join('&');
        } else if (pathnameParams.length > 1) {
            query = pathnameParams.slice(1).join('&');
        }

        const urlParams: URLSearchParams = new URLSearchParams(query);

        EXCLUDE_LIST.forEach((item: string) => {
            return urlParams.delete(item);
        });

        let params: string = urlParams.toString();
        params = params ? `?${params}` : '';

        return `${url.origin}${pathname}${params}${url.hash}`;
    }
}
